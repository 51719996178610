import { create } from 'zustand';

import { UpdateFindingDetails } from '@/types/structuredFinding';

import { FindingsState, FindingSummary } from './useFindingsStore.types';

export const useFindingsStore = create<FindingsState>((set, get) => ({
  addInitialFinding: (organ: string, observation: string) => {
    set((state) => {
      const organFindings = state.organs[organ] || [];
      const initialFinding: FindingSummary = {
        conditionTemplate: {
          actionDetailTemplates: '',
          actionSummaryTemplates: '',
          condition: '',
          conditionDetail: [],
          findingDetailTemplates: '',
          findingSummaryTemplates: '',
          forms: [],
          icdCode: '',
          inputs: [],
          organ,
          status: '',
          uuid: '',
        },
        error: null,
        observation,
        progress: true,
        renderedSummary: {
          actionDetails: '',
          actionSummary: '',
          findingDetails: '',
          findingSummary: '',
          reportFindings: [],
          reportFindingsUuids: [],
          reportUuid: '',
          uuid: '',
        },
      };

      return {
        organs: {
          ...state.organs,
          [organ]: [...organFindings, initialFinding],
        },
      };
    });
  },
  deleteFinding: (organ: string, reportUuid: string) => {
    set((state) => {
      const organFindings = state.organs[organ] || [];
      const updatedFindings = organFindings.filter(
        (finding) => finding.renderedSummary.uuid !== reportUuid,
      );

      return {
        organs: {
          ...state.organs,
          [organ]: updatedFindings,
        },
      };
    });
  },
  getFindingsByOrgan: (organ: string) => get().organs[organ] || [],
  organs: {},
  selectedOrgan: '',
  setOrgans: (organs: { [key: string]: FindingSummary[] }) => {
    set({ organs });
  },
  setSelectedOrgan: (organ: string) => {
    set({ selectedOrgan: organ });
  },
  updateFindingStatus: (
    organ: string,
    observation: string,
    updatedFinding: UpdateFindingDetails,
    progress: boolean,
    error: null | string,
    index?: number,
  ) => {
    set((state) => {
      const organFindings =
        state.organs[organ] &&
        state.organs[organ].map((finding, findingIndex) => {
          const isSameObservation = finding.observation === observation;
          const isDifferentObservationSameIndex =
            finding.observation !== observation && index === findingIndex;
          const hasProgressOrSameIndex = finding.progress || index === findingIndex;

          if ((isSameObservation || isDifferentObservationSameIndex) && hasProgressOrSameIndex) {
            return {
              ...finding,
              ...updatedFinding,
              error,
              observation,
              progress,
            };
          }

          return finding;
        });

      return {
        organs: {
          ...state.organs,
          [organ]: organFindings,
        },
      };
    });
  },
}));
