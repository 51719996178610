import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AlignLeft } from '@prenuvo/halo-icon';
import { Icon, Typography } from '@prenuvo/halo-web';

import { Editor } from '@/components/Editor';
import { useSaveTechniqueNotes } from '@/hooks/mutations/useSaveTechniqueNotes/useSaveTechniqueNotes';
import { useHiStudy } from '@/store';
import { usePatient } from '@/store/usePatient/usePatient';
import { useReport } from '@/store/useReport/useReport';

export function Technique({ isReadOnly = false }: { isReadOnly?: boolean }) {
  const { scanDetails } = useHiStudy();
  const { patient } = usePatient();
  const { report } = useReport();

  const [techniqueNotes, setTechniqueNotes] = useState('');

  const { id: studyId } = useParams<{ id: string }>();
  const mutation = useSaveTechniqueNotes(studyId || '');

  const formatNote = (note: string) => note?.replace(/<[^>]*>?/g, '').trim();

  const saveTechnique = (updatedNote: string = '') => {
    const newNote = formatNote(updatedNote);
    const CurrentNote = formatNote(techniqueNotes);

    if (newNote !== CurrentNote) {
      mutation.mutate({ techniqueNotes: updatedNote });

      if (mutation.isSuccess) {
        setTechniqueNotes(updatedNote);
      }
    }
  };

  useEffect(() => {
    if (report) {
      const notesFromReport = report?.techniqueNotes;

      setTechniqueNotes(notesFromReport);
    }
  }, [report]);

  return (
    <div className="space-y-4">
      <Typography as="div" data-testid="technique-title" variant="paragraph-md-bold">
        Technique
      </Typography>
      <div className="space-y-4">
        <div
          className={` ${!isReadOnly && 'rounded-md border border-neutral-800 p-4'}`}
          data-testid="technique-data"
        >
          {patient && scanDetails.technique && patient.gender ? (
            <div className="flex gap-2">
              {!isReadOnly && <Icon className="fill-neutral-400" size="lg" source={AlignLeft} />}
              <Typography data-testid="technique-patient-result">
                {scanDetails.technique[patient.gender]}
              </Typography>
            </div>
          ) : (
            <Typography>Not provided</Typography>
          )}
        </div>
        <div
          className={` ${!isReadOnly && 'rounded-md border border-neutral-800 p-4'}`}
          data-testid="add-notes-to-technique"
        >
          <div className="flex gap-2">
            {!isReadOnly && <Icon className="fill-neutral-400" size="md" source={AlignLeft} />}
            <div className="max-h-40 w-full overflow-y-auto">
              <Typography as="div" variant="paragraph-sm">
                <span className="text-neutral-400">
                  {isReadOnly ? 'Additional Note' : 'Add notes to technique'}
                </span>
              </Typography>
              <Typography as="div" variant="paragraph-md">
                <Editor
                  isReadOnly={isReadOnly}
                  onBlur={(_, current) => saveTechnique(current)}
                  placeholder="Type your notes here"
                  value={techniqueNotes}
                />
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
