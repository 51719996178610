import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChevronRight, Edit } from '@prenuvo/halo-icon';
import { Button, Checkbox, Dialog, Select, Switch, Typography } from '@prenuvo/halo-web';

import { Technique } from '@/components/ReasonForScan/components/Technique';
import { Section } from '@/components/Report/Components';
import {
  mergeOrgansAndonDemandConditions,
  useTransformedReportData,
} from '@/components/Report/Hooks';
import type { Report as ReportType } from '@/components/Report/Report.type';
import { Signature } from '@/components/Signature';
import { API_ENDPOINTS } from '@/core/api/endpoints';
import { HttpMethod } from '@/core/constants';
import { useAuthStore } from '@/core/store/auth/authStore';
import { useApiRequest } from '@/hooks/useApiRequest/useApiRequest';
import { useFetchFormStructure } from '@/hooks/useFetchFormStructure/useFetchFormStructure';
import { useFetchReport } from '@/hooks/useFetchReport/useFetchReport';
import { useFindingsStore } from '@/store/useFindings/useFindingsStore';
import { useOnDemandConditionsStore } from '@/store/useOnDemandConditions/useOnDemandConditionsStore';
import { useReport } from '@/store/useReport/useReport';

export function Report() {
  useFetchReport();
  useFetchFormStructure();

  const { organs } = useFindingsStore();
  const { onDemandConditons } = useOnDemandConditionsStore();
  const updatedOrgansData = mergeOrgansAndonDemandConditions(organs, onDemandConditons);
  const reportData = useTransformedReportData(updatedOrgansData);
  const [isSigned, setIsSigned] = useState(false);
  const [isSensitive, setIsSensitive] = useState(false);
  const [isUrgent, setIsUrgent] = useState(false);
  const { report, setReport } = useReport();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [addSecondSignature, setAddSecondSignature] = useState(false);
  const [isSecondSigned, setIsSecondSigned] = useState(false);
  const [radiologist, setRadiologist] = useState<string>('');

  const { handleApiRequest } = useApiRequest();
  const { user } = useAuthStore();

  const { id: studyId } = useParams<{ id: string }>();

  const saveReport = async () => {
    if (report && user) {
      const reportDataObj = {
        authors: [user?.user_id],
        sensitive: isSensitive,
        status: report.status,
        studyId: report.studyId,
        urgent: isUrgent,
        uuid: report.uuid,
      };

      try {
        await handleApiRequest<ReportType>(
          `${API_ENDPOINTS.report.saveReport}/${studyId}`,
          HttpMethod.PATCH,
          reportDataObj,
          (reportObj) => {
            setReport(reportObj);
            setIsDialogOpen(true);
          },
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error while saving report', error);
      }
    }
  };

  const onAddSecondSignatureHandler = () => {
    if (addSecondSignature) {
      setRadiologist('');
      setIsSecondSigned(false);
    }

    setAddSecondSignature((prevState) => !prevState);
  };

  const onRadiologistSelectHandler = (value: string) => {
    setRadiologist(value);
  };

  if (!reportData.length) {
    return null;
  }

  return (
    <div className="custom-scrollbar h-[calc(100vh-5rem)] overflow-y-auto">
      <Dialog
        data-testid="report-submitted-dialog"
        onOpenChange={(open) => setIsDialogOpen(open)}
        open={isDialogOpen}
        title={{
          children: 'Report Submitted',
        }}
      >
        <Typography className="text-neutral-400 dark:text-neutral-400" variant="paragraph-lg">
          Your report has been submitted successfully.
        </Typography>
      </Dialog>
      <div>
        <div className="mt-4 px-[162px]">
          <Technique isReadOnly />
        </div>
        {reportData.map((sectionData) => (
          <Section key={sectionData.sectionName} sectionData={sectionData} />
        ))}
      </div>
      <div className="border-t-4 border-neutral-800">
        <div className="mt-8 space-y-16 px-[162px]">
          <Signature
            data-testid="signature-component"
            description="Please sign below"
            onFinishSigning={(isSignedData) => setIsSigned(isSignedData)}
            signatureData={user?.signature}
            title="My Signature"
          />
          {addSecondSignature && (
            <div data-testid="second-signature-component">
              <Typography variant="h5">Second signature:</Typography>
              <Typography className="text-neutral-400 dark:text-neutral-400" variant="paragraph-sm">
                Requiring a second signature will assign this study to another selected radiologist
                once published.
              </Typography>
              {!radiologist && (
                <>
                  <Select
                    className={{ trigger: 'mt-4 h-[50px] w-[250px] rounded-xl' }}
                    data-testid="radiologist-select"
                    leftIcon={Edit}
                    onValueChange={(value) => onRadiologistSelectHandler(value)}
                    options={[
                      {
                        value: 'Radiologist-1',
                      },
                      {
                        value: 'Radiologist-2',
                      },
                    ]}
                    placeHolder={{
                      translationKey: 'Select Radiologist',
                    }}
                  />
                  <Typography
                    className="text-neutral-400 dark:text-neutral-400"
                    variant="paragraph-xs"
                  >
                    Radiologist will receive the study in their dashboard
                  </Typography>
                </>
              )}
              {radiologist && (
                <Signature
                  onFinishSigning={(isSecondSignedData) => setIsSecondSigned(isSecondSignedData)}
                />
              )}
            </div>
          )}
          <div className="flex w-full items-center gap-8 py-8">
            <div>
              <Switch
                data-testid="add-second-signature-switch"
                isChecked={addSecondSignature}
                onChange={onAddSecondSignatureHandler}
              />
              <Typography
                as="label"
                className="ml-4 text-sm font-normal text-neutral-400 dark:text-neutral-400"
                data-testid="add-second-signature-label"
              >
                Add second signature
              </Typography>
            </div>
            <div className="ml-auto flex flex-row">
              <div className="flex items-center gap-2">
                <Checkbox
                  checked={isSensitive}
                  data-testid="report-sensitive-section-checkbox"
                  onChange={(event) => setIsSensitive(event.target.checked)}
                />
                <Typography
                  as="label"
                  className="ml-2 mr-10 text-sm font-normal text-neutral-400 dark:text-neutral-400"
                  data-testid="report-sensitive-section-label"
                >
                  Sensitive
                </Typography>
              </div>
              <div className="flex items-center gap-2">
                <Checkbox
                  checked={isUrgent}
                  data-testid="report-urgent-section-checkbox"
                  onChange={(event) => setIsUrgent(event.target.checked)}
                />
                <Typography
                  as="label"
                  className="ml-2 mr-10 text-sm font-normal text-neutral-400 dark:text-neutral-400"
                  data-testid="report-urgent-section-label"
                >
                  Urgent
                </Typography>
              </div>
              <Button
                aria-label="Review and Sign"
                data-testid="review-sign-button"
                disabled={!isSigned || (addSecondSignature && !isSecondSigned)}
                onClick={saveReport}
                rightIcon={ChevronRight}
                size="lg"
                variant="primary"
              >
                Review and Sign
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
