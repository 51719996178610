import { useMemo } from 'react';
import { QuestionCircle } from '@prenuvo/halo-icon';
import { IconSource } from '@prenuvo/halo-web';

import { ORGAN_SECTIONS } from '@/mocks/constants';
import { FindingSummary } from '@/store/useFindings/useFindingsStore.types';
import { useFormStructure } from '@/store/useFormStructure/useFormStructure';
import type { OnDemandConditionsData } from '@/store/useOnDemandConditions/useOnDemandConditionsStore.types';
import { FormStructure } from '@/types/formStructure';

import { FindingReport } from '../Components/Finding';
import { SectionData } from '../Components/Section';

const getIconByFormStructure = (title: string, formStructure: FormStructure) => {
  const organ = formStructure.structure.find(
    (organData) => organData.key && organData.key === title,
  );

  return (organ?.icon || QuestionCircle) as IconSource;
};

const parseOrganTitle = (organ: string) =>
  organ
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const useTransformedReportData = (reportFindings: {
  [key: string]: FindingSummary[];
}): SectionData[] => {
  const { formStructure } = useFormStructure();

  const transformReportData = (reportFindingsData: {
    [key: string]: FindingSummary[];
  }): SectionData[] =>
    Object.entries(ORGAN_SECTIONS).reduce((result, [section, organs]) => {
      const organsData = organs.reduce(
        (acc, organ) => {
          const findings = reportFindingsData[organ];

          if (findings) {
            const transformedFindings = findings.map(
              (finding): FindingReport => ({
                actionDetails: finding.renderedSummary.actionDetails,
                actionSummary: finding.renderedSummary.actionSummary,
                error: finding.error,
                findingDetails: finding.renderedSummary.findingDetails,
                findingSummary: finding.renderedSummary.findingSummary,
                observation: finding.observation,
                organ: finding.conditionTemplate.organ || '',
                progress: finding.progress,
                uuid: finding.renderedSummary.uuid,
              }),
            );

            acc.push({
              findings: transformedFindings,
              icon: getIconByFormStructure(organ, formStructure as FormStructure),
              title: parseOrganTitle(organ),
            });
          }

          return acc;
        },
        [] as { findings: FindingReport[]; icon: IconSource; title: string }[],
      );

      if (organsData.length > 0) {
        result.push({
          organs: organsData,
          sectionName: section,
        });
      }

      return result;
    }, [] as SectionData[]);

  return useMemo(() => transformReportData(reportFindings), [reportFindings, formStructure]);
};

export const mergeOrgansAndonDemandConditions = (
  organs: { [key: string]: FindingSummary[] },
  onDemandConditons: {
    [key: string]: OnDemandConditionsData[];
  },
) =>
  Object.entries(onDemandConditons).reduce(
    (updatedOrgansData, [key, conditions]) => {
      const updatedOrgans = { ...updatedOrgansData };

      updatedOrgans[key] = conditions.reduce((organData, condition) => {
        const updatedOrganData = [...organData];

        if (condition.renderedSummary) {
          const updatedData = {
            conditionTemplate: { organ: key },
            error: null,
            observation: '',
            progress: false,
            renderedSummary: condition.renderedSummary,
          };

          const index = updatedOrganData.findIndex(
            (organ) => organ.renderedSummary.uuid === condition.renderedSummary!.uuid,
          );

          if (index !== -1) {
            updatedOrganData[index] = updatedData;
          } else {
            updatedOrganData.push(updatedData);
          }
        }

        return updatedOrganData;
      }, updatedOrgans[key] || []);

      return updatedOrgans;
    },
    { ...organs },
  );
