import { BrowserRouter } from 'react-router-dom';
import { HaloProvider } from '@prenuvo/halo-web';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AuthProvider } from '@/contexts/authContext/AuthContexts';
import { ThemeProvider } from '@/contexts/ThemeContext/ThemeContext';

import { AppRouter } from './routes';

const queryClient = new QueryClient();

function App() {
  return (
    <HaloProvider
      language="en"
      locales={{
        en: {
          /** Your locales here */
        },
      }}
    >
      <BrowserRouter>
        <AuthProvider>
          <ThemeProvider defaultTheme="dark">
            <QueryClientProvider client={queryClient}>
              <AppRouter />
            </QueryClientProvider>
          </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </HaloProvider>
  );
}

export { App };
